import React from "react";
import "./configurerobot.css"

class ConfigureRobot extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: props.name || "",
            ipaddress: props.ipaddress || "",
            serialnumber: props.serialnumber || "",
            time: Date().toLocaleString(),
            result: false,
            response: "",
            errormessage: ""
        };
        this.handlesubmit = this.handlesubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleIpChange = this.handleIpChange.bind(this);
        this.handleNumChange = this.handleNumChange.bind(this);
    }

    handlesubmit(){
        var url = "https://boiling-everglades-22768.herokuapp.com/robots/newdevice"
        // var url = "http://localhost:5000/robots/newdevice"
        fetch(url, {
        method: 'post',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        },
        body: JSON.stringify({
            "name": this.state.name,
            "ipaddress": this.state.ipaddress, 
            "serialNumber": this.state.serialnumber,
            "time": this.state.time
        }),
        })
        .then(response=> response.json())
        .then(data=>{ 
            console.log(data)
            this.setState({response: data, result: true})
        })
        .catch((error)=>{
            this.setState({errormessage: "Duplicate Entry"});
        });
    }

    handleNameChange(e){
        this.setState({name: e.target.value})
    }   
    handleIpChange(e){
        this.setState({ipaddress: e.target.value})
    }  
    handleNumChange(e){
        this.setState({serialnumber: e.target.value})
    }

    render(){
        const {name, ipaddress, serialnumber} = this.state;
        const isEnabled = name.length > 0 && ipaddress.length > 0 && serialnumber.length > 0; 
        return(
            <div className="configurerobot">
                <div className="configform">
                    <label>Name</label><input value={this.state.name} onChange={this.handleNameChange}></input><br></br>
                    <label>IP</label><input autoComplete="on" value={this.state.ipaddress} onChange={this.handleIpChange}></input><br></br>
                    <label>Serial Number</label><input autoComplete="on" value={this.state.serialnumber} onChange={this.handleNumChange}></input><br></br>
                    <button disabled={!isEnabled} className="configsubmit" type="submit" onClick={this.handlesubmit}>Submit</button>
                </div>
                <div>
                    {this.state.result === true ? <p>{this.state.response}</p> : <p>{this.state.errormessage}</p>}
                </div>
            </div>
        )
    }

}

export default ConfigureRobot;