import React from "react";
import "./RobotDetails.css";
 
const RobotPopup = props => {
  return (
    <div className="popup-box">
      <div className="box">
      <b>Device Info </b>
        <span className="close-icon" onClick={props.handleClick}>x</span>
        {props.content}
      </div>
    </div>
  );
};

export default RobotPopup;