import './App.css';
import React from "react";
import ConfigureRobot from './configureRobot';
import RobotStatus from './RobotStatus';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configure: false,
      robotstatus: true,
      buttonColorOne: true,
      buttonColorTwo: false
    };
    this.handleSubmitStatus = this.handleSubmitStatus.bind(this);
    this.handleSubmitConfigure = this.handleSubmitConfigure.bind(this);
  }

  handleSubmitStatus(){
    this.setState({configure: false, robotstatus: true, buttonColorTwo: false, buttonColorOne: true});
  }
  handleSubmitConfigure(){
    this.setState({configure: true, robotstatus:false, buttonColorTwo: true, buttonColorOne: false});
  }
render() {
  return (
    <div className="App">
      <h2>ADMIN PANEL</h2>
      <div className="nav-button">
        <button style = {{color: this.state.buttonColorOne ? "white" : "black" , backgroundColor: this.state.buttonColorOne ? "black" : "white"}} className="button1" type='submit' onClick={this.handleSubmitStatus}>ROBOT STATUS</button>
        <button style = {{color: this.state.buttonColorTwo ? "white" : "black" , backgroundColor: this.state.buttonColorTwo ? "black" : "white"}} className="button2" type='submit' onClick={this.handleSubmitConfigure}>CONFIGURE</button>
      </div>
      {this.state.configure && <ConfigureRobot/>}
      {this.state.robotstatus && <RobotStatus/>}
    </div>
  );
}
}
export default App;
