import React from "react";
import "./robotstatus.css";
import RobotPopup from "./RobotPopup";
// import axios from "axios";
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';


class RobotStatus extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            result: false,
            response: "",
            renderRobots: props.renderRobots || "",
            isOpen: false,
            key: "",
            singlerobot: ""
        };
        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    async componentDidMount(){
        var url = "https://boiling-everglades-22768.herokuapp.com/robots/allrobots"
        // var url = "http://localhost:5000/robots/allrobots"
        await fetch(url, {
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
        })
        .then(response=> response.json())
        .then(data=>{ 
            // console.log(data)
            this.setState({response: data, result: true})
        })
        .catch((error)=>{
            console.log("Api call error");
            alert(error.message);
        });
    }


    handleOpen(event){
        // this.setState({isOpen: true})
        // var url = "http://localhost:5000/robots/robotdata?id="+event.target.innerHTML;
        var url = "https://boiling-everglades-22768.herokuapp.com/robots/robotdata?id="+event.target.innerHTML;
        fetch(url, {
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
        })
        .then(response=> response.json())
        .then(data=>{ 
            // console.log(data)
            this.setState({singlerobot: data, isOpen: true})
        })
        .catch((error)=>{
            console.log("Api call error");
            alert(error.message);
        });
    }

    handleClose(){
        this.setState({isOpen: false})
    }

    render(){
        return(
            <div className="RobotStatus">
                {!this.state.result && <div className="lds-facebook"><div></div><div></div><div></div></div>}
                {this.state.result &&
                 <table id="robots">
                    <thead>
                        <tr>
                            <th>Unique ID</th>
                            <th>Name</th>
                            <th>IP Address</th>
                            <th>Serial Number</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.response.map((r, index) => (
                            <tr onClick={event => this.handleOpen(event, index)} key={r._id} value={"hello"}>
                                <td >{r._id}</td>
                                <td>{r.name}</td>
                                <td>{r.ipaddress}</td>
                                <td>{r.serialnumber}</td>
                                {/* <td>
                                    <select>
                                        <option defaultValue={"none"} selected disabled hidden>Action</option>
                                        <option value={this.state.view}>View</option>
                                        <option value={this.state.delete} >Delete</option>
                                    </select>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                }

                {this.state.isOpen && 
                <RobotPopup 
                    content={
                    <>
                        <div className="RobotStatuspopup">
                            <table>
                                <thead>
                                    <tr>
                                        <th>IP Address</th>
                                        <th>Temperature</th>
                                        <th>CPU Load</th>
                                        <th>Memory Usage</th>
                                        <th>Disk Usage</th>
                                        <th>Time Posted</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.singlerobot.map((r, index) => (
                                    <tr key={r._id}>
                                        <td>{r.ipaddress}</td>
                                        <td>{r.temperature}</td>
                                        <td>{r.cpuload}</td>
                                        <td>{r.memusage}</td>
                                        <td>{r.diskusage}</td>
                                        <td>{r.time}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {/* <button type="submit">Close</button> */}
                        </div>
                    </>
                    }
                    handleClick={this.handleClose}
                />} 
            </div>
        )
    }
}

export default RobotStatus;